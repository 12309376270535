body {
  display:flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-layout-wrapper {
  flex:1;
  display:flex;
  flex-direction:column;
  align-items:stretch;
}

.main-sidebar {
  padding:10px 15px;
  background-color:#eee;
  border-top:4px solid #5755d9;
}
.main-sidebar p {
  font-size:.6rem;
  margin-bottom:.3rem;
}
.main-sidebar p:last-child {
  margin-bottom:0;
}

.main-content {
  width:100%;
}

@media only screen and (min-width: 960px) {
  .main-layout-wrapper {
    flex-direction: row;
  }

  .main-sidebar {
    padding:20px;
    max-width:300px;
    font-size:1rem;
    border-right:1px solid #a1a1c1;
    box-shadow: 0 4px 10px #aaa;
  }

  .main-sidebar p {
    font-size:0.66rem;
    margin-bottom:.8rem;
  }

  .main-content {
    padding:5px 20px 0;
  }
}

.main-sidebar__title {
  font-size:24px;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}